import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getUser, setUser } from '../../services/userStorage';
import { User } from '../../model/User';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import EditIcon from '@mui/icons-material/Edit';
import "./styles.css"
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'absolute',
            right: 10,
            bottom: 10
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);





export default function EditUserCard(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [values, setValues] = React.useState<User>({
        access_token: "",
        address: "",
        login: "",
        phone: "",
        userNane: "",
    });

    const handleChange =
        (prop: keyof User) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const [authMode, setAuthMode] = useState<boolean>(false);

    // Аналогично componentDidMount и componentDidUpdate:
    useEffect(() => {
        getUser().then((user: User) => {
            if (user != null) {
  
                setValues(user)

            }
            else {
                navigate("/login/user")
                // setAuthMode(false)
            }
        })



    }, []);


    var oncEditlick = () => {
        getUser().then((user: User) => {
            var a = user.access_token;
            var obj = {
                Login: values.login,
                Phone: values.phone,
                Name: values.userNane,
                Address: values.address,
                OldPhone: user.phone
            }
             fetch(process.env.REACT_APP_TITLE+"/account/update",
                {
                    method: "POST",
                    body: JSON.stringify(obj),
                    headers: {
                     'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + a,
                    }
                })

                .then((response: any) => {
                      
                    if (response.status == 400 || response.status == 401) {
                        navigate("/user")
                    }
                    else {
                        return response.json()
                    }
                })
                .then((res: User) => {
                    if (res != undefined) {
                        setUser(res);
                        navigate("/user")
                        return res;
                    }
                 });
        })
    }



    return (
        <div style={{ width: '100%' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" gutterBottom component="div">
                    Контактные данные
                </Typography>
                <IconButton aria-label="delete" onClick={() => oncEditlick()}>
                    <EditIcon />
                </IconButton>
            </div>
            <Box sx={{ width: '100%' }}>
                <Stack spacing={2}>

                    <TextField

                        id="filled-disabled"
                        label="Логин"
                        
                        variant="outlined"
                        onChange={handleChange('userNane')}
                        value={values.userNane}
                    />

                    <TextField

                        id="filled-disabled"
                        label="Адрес"
                        value={values.address}
                        variant="outlined"
                        onChange={handleChange('address')}
                    />

                    <TextField
disabled
                        id="filled-disabled"
                        label="Мобильный"
                        value={values.phone}
                        variant="outlined"
                        onChange={handleChange('phone')}
                    />

                    <Button variant="contained" onClick={()=>{oncEditlick()}}>Сохранить</Button>
                </Stack>
            </Box>
        </div>
    );
}