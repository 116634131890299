import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, FormControl, IconButton, Input, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import makeRequest from '../../api/makeRequest';
import { setUser } from '../../services/userStorage';
import { User } from '../../model/User';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,

            right: 10,
            bottom: 10
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);




interface State {
    mobile: string;
    password: string;
    weight: string;
    weightRange: string;
    showPassword: boolean;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Register(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [mobile, setMobile] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [values, setValues] = React.useState<State>({
        mobile: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });


    const [users, setUsers] = React.useState<State>({
        mobile: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleChange1 =
        (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    var onClick1 = () => {
        var user = { Phone: "+7"+values.mobile, Password: values.password }
          
         fetch(process.env.REACT_APP_TITLE+"/account/register",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(user)
            })

            .then((response: any) => {
                  

                if (response.status == 400 || response.status == 401) {
                    return;
                }

                return response.json()
            })
            .then((res: User) => {
                  
                if (res != undefined) {
                    setUser(res);
                    /*    (this.props as any).authUser();
   
                       this.setState({
                           isLoading:false
                       }) */

                    navigate("/user")
                    return res;
                }
            })
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [value, setValue] = React.useState(0);
    return (

        <div>
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Номер</InputLabel>
                <Input
                    id="standard-adornment-amount"
                    value={values.mobile}
                    onChange={handleChange1('mobile')}
                    startAdornment={<InputAdornment position="start">+7</InputAdornment>}
                />
            </FormControl>


            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <InputLabel htmlFor="standard-adornment-amount">Пароль</InputLabel>
                <Input
                    id="standard-adornment-password"
                    type={values.showPassword ? 'text' : 'password'}
                    value={values.password}
                    onChange={handleChange1('password')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {values.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    }
                /></FormControl>

            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Button onClick={() => { onClick1() }}>Зарегистрироваться</Button>
            </FormControl>
        </div>

    );
}