import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

export default function HistoryItem(props: any) {
  return (
    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
      {
        props.items?.map((item: any) => {
          return <div style={{ width: '100%' }}>
            <ListItem alignItems="flex-start"   >

              <div>

                <img height={100} src={item.image} />

              </div>
              {/*  <ListItemAvatar>
                <Avatar alt="Remy Sharp" src={item.image} />
              </ListItemAvatar> */}
              <ListItemText
                style={{ paddingLeft: 10, width: '100%' }}

                primary={item.name}
                secondary={
                  <React.Fragment >
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color=""
                    >
                      {item.price} ₽
                    </Typography>



                    {/*  {" — I'll be in your neighborhood doing errands this…"} */}
                  </React.Fragment>


                }
              />

              <div style={{marginTop:10}}> {item.count}  </div>
            </ListItem>
            {/*    */}
          </div>
        })
      }

    </List>
  );
}
