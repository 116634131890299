
export async function retrieveData() {
    try {
         
        let value = await localStorage.getItem("@basket");
        var jsonValue = value != null ? JSON.parse(value as any) : null;
        if ((jsonValue as any).length > 0) {
             
            return {
                cost: Number((jsonValue as any).map((c: any) => (Number(c.obj.price) +  Number(c.obj.sum == undefined ? 0 :c.obj.sum ) ) * c.count).reduce((sum: any, current: any) => sum + current)).toFixed(2),
                newCost: Number((jsonValue as any).map((c: any) => (Number(c.obj.newPrice == null ? c.obj.price : c.obj.newPrice) + Number(c.obj.sum == null ? 0 :c.obj.sum )) * c.count).reduce((sum: any, current: any) => sum + current)).toFixed(2),
                data: jsonValue
            }
        }
        else {
            localStorage.setItem('@basket', "[]");
            return {
                newCost:0,
                cost: 0,
                data: []
            }
        }
    }
    catch (error) {
        localStorage.setItem('@basket', "[]");
        return {
            newCost: 0,
            cost: 0,
            data: []
        }
    }
}
export async function setTags(val: any) {
    localStorage.setItem('@tags', JSON.stringify(val));
}

export async function getTags() {
    let value = await localStorage.getItem("@tags");
    return value != null ? JSON.parse(value as any) : null;
}


export async function increaseCost(id: number, info: any) {
     
    let value = await localStorage.getItem("@basket");
      ;
    let jsonValue = value != null ? JSON.parse(value) : null;

    if (jsonValue !== null && jsonValue.length > 0) {
        jsonValue.find((x: any) => x.obj.id == id  && x.obj.info == info).count++
        let dataItem = jsonValue.find((x: any) => x.obj.id == id && x.obj.info == info).count
        let maxItemCount = 9;
        if (dataItem <= maxItemCount) {
            localStorage.removeItem("@basket");
            localStorage.setItem("@basket", JSON.stringify(jsonValue));

            return;
        }
    }

}

export async function getObject(id: number) {
    let value = await localStorage.getItem("@basket");;
    let jsonValue = value != null ? JSON.parse(value) : null;
    if (jsonValue !== null && jsonValue.length > 0) {


        var dataItem = jsonValue.find((x: any) => x.obj.id == id)
        return dataItem;
    }

}

export async function reduceCost(id: number, info:any) {
 
    let value = await localStorage.getItem("@basket");;
    let jsonValue = value != null ? JSON.parse(value) : null;

    if (jsonValue !== null && jsonValue.length > 0) {

        
        var res = jsonValue.find((x: any) => x.obj.id == id && x.obj.info == info  )
       
        res.count--
        
      
        var dataItem = jsonValue.find((x: any) => x.obj.id == id  && x.obj.info == info).count

        if (dataItem == 0) {
            
            jsonValue = jsonValue.filter((x: any) => !(x.obj.id == id && x.obj.info == info));
        }

        localStorage.removeItem("@basket");
        localStorage.setItem("@basket", JSON.stringify(jsonValue));

        return;
    }

}


export async function addItem(item: any) {
    let value = await localStorage.getItem('@basket')

     

    let jsonValue = value != null ? JSON.parse(value) : null;
    var res = (jsonValue as any)?.find((x: any) => x.obj.id == item.id)
    if (res !== undefined) {
        let maxItemCount = 9;
        var dataItemCount = (jsonValue as any).find((x: any) => x.obj.id == item.id).count;
        if (dataItemCount !== maxItemCount)
            (jsonValue as any).find((x: any) => x.obj.id == item.id).count = res.count + 1
    }
    else {
        
        if (jsonValue == null) {
            jsonValue = [{ obj: item, count: 1 }]
        }
        else {
            (jsonValue as any).push({ obj: item, count: 1 })
        }
        //  (jsonValue as any).push({ obj: item, count: 1 });
    }

    var res = (jsonValue as any).map((c: any) => {
        return Math.round(Number(c.obj.price) * c.count);
    }).reduce((sum: any, current: any) => sum + current);

    localStorage.setItem('@basket', JSON.stringify(jsonValue));

    return res;
}



export async function addItemWithOption(item: any) {
    let value = await localStorage.getItem('@basket')

     

    let jsonValue = value != null ? JSON.parse(value) : null;
    
    (jsonValue as any).push({ obj: item, count: 1 })
        

    var res = (jsonValue as any).map((c: any) => {
        return (Math.round(Number(c.obj.price)  + c.obj.sum) * c.count);
    }).reduce((sum: any, current: any) => sum + current);

    localStorage.setItem('@basket', JSON.stringify(jsonValue));

    return res;
}

export function clearBasket() {
    localStorage.setItem('@basket', "[]");
}


export async function setContactInfo(val: any) {
    localStorage.setItem('@contactInfo', JSON.stringify(val));
}

export async function getContactInfo() {
    let value = await localStorage.getItem("@contactInfo");
    return value != null ? JSON.parse(value as any) : null;
}




//Временная работа поиска

export async function setAllProducts(val: any) {

      
    // var res = JSON.stringify(val);
    // val = val.filter(x=>{return x.id > 319})

    localStorage.setItem('@products', JSON.stringify(val));
}

export async function getAllProducts() {
    let value = await localStorage.getItem("@products");
    return value != null ? JSON.parse(value as any) : null;
}


export async function setOrders(item: any) {
    let value = await localStorage.getItem('@orders')

    let jsonValue = value != null ? JSON.parse(value) : [];

    (jsonValue as any).push(item);

    localStorage.setItem('@orders', JSON.stringify(jsonValue));
}

    
/* export async function setOrders(val : any) {
    localStorage.setItem('@orders', JSON.stringify(val));       
}
 */
export async function getOrders() {
    let value = await localStorage.getItem("@orders");
    return value != null ? JSON.parse(value as any) : null;
}

