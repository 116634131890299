import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SaleList from './SaleList';
import BasketButton from '../Purchase/BasketButton';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /*  position: 'fixed',
             bottom: 20,
             right: 20 */
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function SaleContainer(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [saleList, setSaleList] = useState<any[]>([]);
    const [flag, setfFlag] = useState<boolean>(false);
    
    useEffect(() => {

        fetch(process.env.REACT_APP_TITLE + `/product/sale`, {
            method: "GET",

            headers: {
                'Content-Type': 'application/json',
            }
        }
        )
            .then(response => {
                
                return response.json()
            })

            .then(x =>{
                setSaleList(x)
            })


    }, []);

    

    var refresh = () => {
        setfFlag(!flag);
    }


    return (
        

<div style={{ paddingBottom: 20 }}>
            <div style={{paddingBottom:50}}>
                <SaleList 
                saleList={saleList}  
                refresh={refresh}
              //  handleClick={handleClick}
                ></SaleList>
            </div>
            <div>
                <BasketButton flag={flag}></BasketButton>
            </div>
        </div>

    );
}


