import { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { Tag } from '../../model/Tag';
import { Card, CardActionArea, CardContent, CardMedia, Chip, IconButton, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { Item } from '../../model/Item';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { addItem, increaseCost, reduceCost, retrieveData } from '../../services/storageService'
import Slider2 from '../../Core/Slider2'
import CircularProgress from '@mui/material/CircularProgress';
import PriceField from '../Items/PriceField'

import './styles.css'
import { Refresh } from '@mui/icons-material';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootq: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },

        card_content: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',


        },
        purchase_button: {
            alignSelf: 'flex-end',
            position: 'fixed',
            bottom: 450
        },
        mainConatinerStyle: {
            paddingTop:10,
            flexDirection: 'column',
            flex: 1
        }
    }),
);


export default function SaleList(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    const params = useParams();

    var tags = (props as any).tags;
    var items = (props as any).saleList;
    var t: JSX.Element[] = [];
    const [tagged, setTagged] = useState<any[]>([]);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    var onChipClicked = (id: number) => {
        setIsLoaded(true)
        navigate(`/item/${params.id}/sub/${id}`)
        //props.handleClick(id, params.id)
        //setSelectedTag(id)
    }


    useEffect(() => {
        setIsLoaded(true)
        retrieveData().then((data: any) => {

            setTagged(data);
            setIsLoaded(false)
        })

    }, [props.items]);

    var refresh = () => {
        setIsLoaded(true)
        retrieveData().then((data: any) => {
            props.refresh();
            setTagged(data);
            setIsLoaded(false)
        })
    }

   /*  var onPlusButtonPress = (id: number) => {
        increaseCost(id).then(() => {
            refresh()
        })
    } */

    var onMinusButtonPress = (id: number, info: string) => {
        reduceCost(id,info).then(() => {
            refresh()
        })
    }

    var navigateToDeatail = (value: any) => {

        navigate(`/detail/${value.id}`)
        /*   addItem(value).then((value: any) => {
                refresh();
                // (props as any).setTextHolder(value)
            }) */

    }



    var addToBasket = async (value: any) => {


        addItem(value).then((value: any) => {
            refresh();
            // (props as any).setTextHolder(value)
        })

    }

    return (


        <div className={classes.mainConatinerStyle}>


            <div>
             
            
                {
                    !isLoaded ?
                        < Grid container spacing={2} >
                            {




                                items.map(function (x: Item, i: any) {
                                    return <Grid item xs={4}>
                                        <Card >
                                            <CardActionArea

                                            >

<div className="card_image_block">
                                        <CardMedia

                                            component="img"
                                            className="card_image"
                                            style={{ borderRadius: 20 }}
                                            image={"" + x.imageUrl}

                                            onClick={() => {

                                                 navigateToDeatail(x)
                                            }}
                                        />
                                    </div>

                                              {/*   <CardMedia

                                                    component="img"
                                                    className="card_image"
                                                    style={{ borderRadius: 20 }}
                                                    image={"" + x.imageUrl}

                                                    onClick={() => {

                                                        navigateToDeatail(x)
                                                    }}
                                                /> */}


                                                <CardContent className="product_card" style={{ padding: 10, width: '100%' }}>

                                                    <div>
                                                        {
                                                            x.newPrice == null
                                                                ?

                                                                <div className="block__old_price">{x.price} ₽</div>

                                                                :
                                                                <div className="price_block_for_sale">
                                                                    <div className="sale_block__new_price">{x.newPrice} ₽</div>
                                                                    <div className="sale_block__old_price">{x.price} ₽</div>

                                                                </div>
                                                        }
                                                    </div>
                                                    {/*    <Typography variant="body2" component="h2"
                                                        className="price_name"
                                                        style={{
                                                            whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden',
                                                            WebkitLineClamp: 2, display: '-webkit-box', WebkitBoxOrient: 'vertical',
                                                            height: '100%'
                                                        }}>
                                                        {x.price} ₽

                                                    </Typography> */}
                                                    <div style={{ height: '2rem' }}>






                                                        <Typography
                                                            className="price_name"
                                                            variant="body2" component="h2" style={{
                                                                whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden',
                                                                WebkitLineClamp: 2, display: '-webkit-box', WebkitBoxOrient: 'vertical',
                                                                height: '100%'
                                                            }}>
                                                            {x.name}

                                                        </Typography>



                                                    </div>


                                                    <div>
                                                        <PriceField item={x} tagged={((tagged as any).data?.filter((item: any) => item.obj.id == x.id))} refresh={() => refresh()}></PriceField>
                                                    </div>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>



                                    </Grid>;



                                })
                            }




                        </Grid>
                        :

                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                            <CircularProgress color="secondary" />
                        </div>

                    /* < Grid container spacing={2} >
                        {
                            [1, 2, 3, 4, 5, 6, 7, 8, 9].map(function (x: any, i: any) {
                                return <Grid item xs={4}>
                                    <Skeleton variant="rectangular" width={'100%'} height={'110px'} />
                                </Grid>;
                            })
                        }
                    </Grid> */
                }
            </div>
            {/*   :
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress color="secondary" />
                    </div>
            } */}
        </div >
    );
}