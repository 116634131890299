export async function setUser(item: any) {    
    localStorage.setItem('@user_new', JSON.stringify(item));
}

export async function deleteUser() {    
    localStorage.removeItem('@user_new');
}

/* export async function setOrders(val : any) {
    AsyncStorage.setItem('@orders', JSON.stringify(val));       
}
 */
export async function getUser() {
    let value = await localStorage.getItem("@user_new");
    if (value == null){
        return null
    }
    else{
        var a = JSON.parse(value as any);
        return a;
    }

}

