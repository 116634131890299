import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, Container, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'absolute',
            right: 10,
            bottom: 10,
            marginTop:20
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function About(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    return (
        <Container style={{marginTop:20}}>

            <img width={'100%'} height={'50%'} src={require("../../assets/images/help.png")} />

            <Typography>
                Мы доставляем по всей Находке. Наш ассортимент великолепен, качество продуктов отменное, а скорость доставки сногсшибательная!
                Доставляем каждый день с 9:00 до 21:00
            </Typography>
        </Container>

    );
}