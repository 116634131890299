import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'absolute',
            right: 10,
            bottom: 10
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function Success(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    return (

        <div style={{ 
       display:"flex",
       justifyContent: "center",
       alignItems: "center",
       flexDirection:"column"
         }}>
            <div style={{ paddingBottom: 20 }}>
                <CheckIcon  sx={{ fontSize: 40 }}></CheckIcon>
            </div>
            <div style={{ fontSize: 24, fontWeight: "700", fontFamily: "Arial", paddingBottom: 10 }}>Ожидайте звонка от курьера</div>
            <div style={{}}>Ваш заказ принят в обработку</div>
            <div>
            <Button onClick={()=>navigate("/")}>Домой</Button>
            </div>
            
        </div>


    );
}