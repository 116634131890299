import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { deleteUser, getUser } from '../../services/userStorage';
import { User } from '../../model/User';
import BrightnessHighIcon from '@mui/icons-material/BrightnessHigh';
import EditIcon from '@mui/icons-material/Edit';
import "./styles.css"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'absolute',
            right: 10,
            bottom: 10
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);





export default function UserCard(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [values, setValues] = React.useState<User>({
        access_token: "",
        address: "",
        login: "",
        phone: "",
        userNane: "",
    });

    const handleChange =
        (prop: keyof User) => (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues({ ...values, [prop]: event.target.value });
        };

    const [authMode, setAuthMode] = useState<boolean>(false);

    // Аналогично componentDidMount и componentDidUpdate:
    useEffect(() => {
        getUser().then((user: User) => {
            if (user != null) {
                setValues(user)

            }
            else {
                navigate("/login/user")
                // setAuthMode(false)
            }
        })



    }, []);


    var oncEditlick = () => {
        navigate("/user_edit");
    }
    var onClick = () => {
        deleteUser()
        navigate("/login/user")
    }



    return (
        <div>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="h6" gutterBottom component="div">
                    Контактные данные
                </Typography>
                <IconButton aria-label="delete" onClick={() => oncEditlick()}>
                    <EditIcon />
                </IconButton>
            </div>
            <Stack spacing={2} style={{ paddingTop: 10 }}>
                <TextField
                disabled
                    id="outlined-read-only-input"
                    label="Имя"
                    defaultValue={values.userNane}
                    value={values.userNane}
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <TextField
                disabled
                    id="outlined-read-only-input"
                    label="Адрес"

                    value={"" + values.address}
                    defaultValue={values.address}
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <TextField
                disabled
                    id="outlined-read-only-input"
                    label="Номер телефона"
                    /*   value={values.phone} */
                    value={"+" + values.phone}
                    defaultValue={"+" + values.phone}
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <Button style={{ marginTop: 5, width: '100%' }} variant="contained" onClick={() => { onClick() }}>Выйти</Button>
            </Stack>

            {/* <Typography variant="subtitle1" gutterBottom component="div">
                {values.userNane}
            </Typography> */}
            {/* 
            <Typography variant="subtitle1" gutterBottom component="div">
                {values.address}
            </Typography> */}
            {/* 
            <Typography variant="subtitle1" gutterBottom component="div">
                {values.phone}
            </Typography> */}
        </div>
    );
}