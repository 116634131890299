import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Tag } from '../../model/Tag';
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, Container, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import { Typography } from '@material-ui/core';
import Link from '@mui/material/Link';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: 'absolute',
            right: 10,
            bottom: 10,
            marginTop: 20
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function Contacts(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    return (
        <Container style={{ marginTop: 20 }}>

            {/*  <img width={'100%'} height={'50%'} src={require("../../assets/images/help.png")} />
 */}
            <div>
                <Typography>
                    Наш основной канал связи – это Instagram <Link href="https://www.instagram.com/avoska.dostavka/">@avoska.dostavka</Link>
                </Typography>
                <Typography>
                    Все новости, события, обновления, акции и промо-коды первым делом публикуются именно там
                </Typography>
            </div>

            <div style={{ marginTop: 20 }}>
                <Typography> Для оперативной связи: <a style={{ color: '#FAA241' }} href="tel:+7 (914) 688-65-78"> +7 (914) 688-65-78 </a>
                </Typography>
                <Typography> Главный Менеджер Авоськи: <a style={{ color: '#FAA241' }} href="tel:+7 (984) 148-93-91"> +7 (984) 148-93-91 </a> Татьяна
                </Typography>
                <Typography>
                    На этом номере ещё есть WhatsApp и Telegram
                </Typography>
            </div>

            <div style={{ marginTop: 20 }}>
                <Typography>
                    Для универсальной связи, если не пользуетесь социальными сетями: E-mail <a style={{ color: '#FAA241' }} href="mailto:dev.avoska.dostavka@gmail.com"> dev.avoska.dostavka@gmail.com</a>
                </Typography>
                <Typography>
                    Отвечаем быстро, всегда всё читаем
                </Typography>
            </div>

            <div style={{ marginTop: 20 }}>
                <Typography>
                    Наш офис: <Link href='https://yandex.ru/maps/974/nahodka/house/vladivostokskaya_ulitsa_15/ZUoEaA9mQUIEWkJuYWJ5cH1hYgg=/?ll=132.886137%2C42.817097&z=14'>Находка, ул. Владивостокская, д. 15</Link>
                </Typography>
            </div>
            
        </Container>

    );
}