import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Badge, Button, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import { retrieveData } from '../../services/storageService';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            /* flexGrow: 1,
            position: 'absolute',
            right: 20,
            bottom: 20 */

            position: 'fixed',
            bottom: 20,
            right: 20
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }),
);



export default function Constructor(props: any) {
    const classes = useStyles();
    const navigate = useNavigate();
    var tags = (props as any).tags;
    const [values, setValues] = React.useState<any>({
        count: 0,
        cost: 0,
        delivery_cost: 0
    });

    useEffect(() => {
        retrieveData().then((value: any) => {

            var c = 0;
            for (var i = 0; i < value.data.length; i++)
                c += value.data[i].count;
            setValues({
                count: c,
                cost: Math.round(value.cost),
                newCost: Math.round(value.newCost),
                delivery_cost: 0
            })
            /* this.setState({
                count: c,
                cost: value.cost,
                deliveryPrice: this.getDeliveryPrice(value.data),
                cost_all: Math.floor(value.cost) + Math.floor(this.getDeliveryPrice(value.data)) ,
            }); */
        })

    }, [props.flag]);

    const handleChange = (item: any, row: any, index: any) => {

        var values = [];
        if (f != null && f.length > 0) {
            values = f
            f = []
        }
        else {
            values = radio;
        }

        if (values.find((x: any) => x.name.includes(row + "_radio"))) {
            values = values.filter((data: any) => !data.name.includes(row + "_radio"));
        }

        values.push({ name: row + "_radio_" + index, value: item.name, price: item.price })
        var sum = values.map(x => x.price).reduce(add, 0)
        var info = values.map(x => x.value).reduce(slice, '')
        props.addSum(sum, info)

        setRadio(values)
        /*   (event.target as HTMLInputElement).value = "0_radio_0"
          setRadio("") */
    };


    var f: any[] = [];
    const handleChange1 = (item: any, row: any, index: any) => {
        var values = f;

        if (radio.length > 0) {
            values = radio

        }
        else {

            //var values = f;
            if (values.find((x: any) => x.name.includes(row + "_radio"))) {
                values = values.filter((data: any) => !data.name.includes(row + "_radio"));
            }


            values.push({ name: row + "_radio_" + index, value: item.name, price: item.price })
            f = values;


        /*   (event.target as HTMLInputElement).value = "0_radio_0"
    setRadio("") */}
        const sum = values.map(x => x.price).reduce(add, 0)
        const info = values.map(x => x.value).reduce(slice, '')
        props.addSum(sum, info)
    };


    var add = (accumulator: any, a: any) => {
        return accumulator + a;
    }


    var slice = (accumulator: any, a: any) => {

        return accumulator == '' ? a : accumulator + ", " + a;
    }
    const [radio, setRadio] = useState<{ name: any, value: any, price: any }[]>([]);
    var a = () => {
        return "0_radio_0"


    }

    var getCheked = (name: any) => {

        var values = [];
        if (radio.length > 0) {
            values = radio;
        }
        else {
            if (name.includes("radio_0")) {
                return true;
            }

            else {
                return false;
            }
        }


        var res = values.map(x => x.name).includes(name)
        return res;
    }
    var getName = (row: any) => {
         
        var values = [];
        if (radio.length > 0) {
            values = radio;
        }
        else {
            values = f;
        }

        var res = values.find((x: any) => x.name.includes(row + "_radio"))

        return res != undefined ? res.value : "";
    }
    var getRadio = (item: any, i: any) => {
        if (item != undefined)
            handleChange1(item.values[0], i, 0)

        return (
            /* <RadioGroup name="use-radio-group" defaultValue="first"
                onChange={handleChange}
               
            > */

            <div style={{ display: 'flex', marginRight: 15 }}>
                {
                    item.values.map((item: any, index: any) => {

                        return (
                            <div style={{ marginRight: 5 }}>
                                <input type="radio" name={i + "_radio_" + index} value={item.name} checked={getCheked(i + "_radio_" + index)} />
                                <img height={40} src={item.picture} onClick={() => handleChange(item, i, index)} />
                                {/*  {item.name} */}
                            </div>
                            /*   <div style={{display:'flex', alignItems:'center'}}>
  
                                  <input type="radio" value={item.name} name={"radio" + i} defaultChecked={index == 0} onChange={() => handleChange(item, "radio" + i)} />
                                  <img height={40} src={item.picture} />
                                  {item.name}
                              </div> */
                            /*  <FormControlLabel
 
                                 value={i + "_radio_" + index}
                                 control={<Radio checked={index == 1}/>}
                                 label={<div>{item.name} + {item.price}₽   </div>} /> */
                        )
                    })

                }


            </div>
            /* </RadioGroup> */
        )
    }

    var getCheck = (items: any) => {
        return (
            <RadioGroup name="use-radio-group" defaultValue="first">

                {
                    items.map((item: any) => {
                        return (
                            <FormControlLabel
                                control={
                                    <Checkbox name="gilad" />
                                }
                                label={<div>{item.name} + {item.price}₽   </div>}
                            />
                        )
                    })
                }
            </RadioGroup>
        )
    }


    var getComponent = (item: any, index: any) => {
        return (
            <div style={{marginTop: 20}}>

                <div style={{ display: 'flex' }}>
                    {
                        item.type = 'radio'
                            ? getRadio(item, index)
                            : getCheck(item)
                    }
                </div>
                <Typography variant="caption" gutterBottom>{item.name} : {getName(index)} </Typography>

            </div>
        )
    }

    return (

        <div >
            <Typography variant="subtitle2" gutterBottom>Настройте блюдо для себя </Typography>

            {

                props.data.map((x: any, index: any) => {
                    return getComponent(x, index)
                })
            }
        </div>
    );
}