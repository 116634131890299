import { IconButton } from "@material-ui/core";
import React from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { VisibilityContext } from "react-horizontal-scrolling-menu";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
function Arrow({
  children,
  disabled,
  onClick
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        right: "1%",

        userSelect: "none"
      }}
    >
      {children}
    </IconButton >
  );
}

export function LeftArrow() {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleItemsWithoutSeparators,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ArrowBackIosNewRoundedIcon onClick={() => scrollPrev()} style={{ color: disabled ? 'lightgray ' : 'black', cursor:'pointer' }}>
      Left
    </ArrowBackIosNewRoundedIcon>
  );
}

export function RightArrow() {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators
  } = React.useContext(VisibilityContext);

  // console.log({ isLastItemVisible });
  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  return (
    <ArrowForwardIosRoundedIcon style={{ color: disabled ? 'lightgray' : 'black', cursor:'pointer' }} onClick={() => scrollNext()}>

    </ArrowForwardIosRoundedIcon>

  );
}
