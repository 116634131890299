import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect } from 'react';
import { getUser } from '../../services/userStorage';
import { User } from '../../model/User';
import { Tag } from '../../model/Tag';
import HistoryItem from './HistoryItem'
import { useNavigate } from 'react-router';
import ItemList from '../Items/ItemList';
import Chip from '@mui/material/Chip';
import './History.css'
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, CircularProgress, IconButton, LinearProgress, Link } from '@material-ui/core';
export default function History() {

    const [orders, setOrders] = React.useState<any>([]);

    const [isLoad, setIsLoad] = React.useState<boolean>(true);

    const navigate = useNavigate();


    var getCount = (items: any) => {

        return items.products.reduce(function (a: any, b: any) {
            return a + b.count;
        }, 0);
    };


    var getSum = (items: any) => {

        return items.products.reduce(function (a: any, b: any) {
            return a + b.count * b.price;
        }, 0);
    };


    useEffect(() => {
        getUser().then((user: User) => {
 
            if (user != null) {
                var token = user.access_token;
                var phone = user.phone;
                fetch(process.env.REACT_APP_TITLE + `/order/a?login=${phone}`, {
                    method: "GET",

                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    }
                }
                )
                    .then(response => {

                        if (response.status == 404 || response.status == 401) {
                            navigate("/login/history")
                        }

                        return response.json()
                    })
                    .then(
                        (result: any[]) => {

                            setOrders(result)
                            setIsLoad(false)
                        }
                        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                        // чтобы не перехватывать исключения из ошибок в самих компонентах.

                    )

            }
            else {
                // navigate("/login")
                // setAuthMode(false)
            }
        })


    }, []);

    var cancel = (orderId: any) => {
        var data = { orderId: orderId, statusId: 5 }

        getUser().then((user: User) => {
            var a = user.access_token;
            fetch(process.env.REACT_APP_TITLE + "/status",
                {
                    method: "POST",
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + a,
                    }
                })

                .then((response: any) => response.json())
                .then((res: boolean) => {
                    window.location.reload();
                })
                .catch((error) => {
                    console.log(error);
                    // (this.props as any).setActivatorState(false)
                    return error;
                });
        })



    }
    var getStatusInfo = (statusid: number, status: string, orderId: any) => {

        switch (statusid) {
            case 1:  // if (x === 'value1')
                return (
                    <div>
                        <Chip label={status} variant="filled" style={{ background: '#9266CC', color: 'white' }} />
                        <Link onClick={() => { return cancel(orderId) }} style={{paddingLeft:10}}>Отменить</Link>
                       {/*  <Button onClick={() => { return cancel(orderId) }} size="small" style={{ fontSize: 10 }}>Отменить</Button> */}
                    </div>
                );
            case 2:  // if (x === 'value2')
            case 3:
                return (
                    <div>
                        <Chip label={status} variant="filled" style={{ background: '#9266CC', color: 'white' }} />
                    </div>


                );
            case 4:
            case 5:
                return (
                    <div>
                        <Chip label={status} variant="filled" />
                    </div>
                )
            default:
                return (<div></div>)
        }

        /*  order.status != 'Выполнен' ?
     
              <Chip label={order.status} variant="filled" style={{ background: '#9266CC', color: 'white' }} />
              :
              <Chip label={order.status} variant="filled" /> */
    }

    return (
        <div style={{ paddingTop: 20 }}>
            {
                (!isLoad) ?
                    orders?.map((order: any) => {
                        var date = new Date(order.createDate);
                        return (
                            <Accordion style={{ marginTop: 10, borderBottomColor: 'lightgray' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <Typography>Заказ # {order.id} от  {date.toLocaleDateString("ru-RU")}</Typography>
                                        <Typography>{getCount(order)} товара(ов) на сумму {getSum(order)} ₽</Typography>
                                        {/* <Typography
                                            sx={{ display: 'inline', color:'#9266CC', fontWeight: 700 }}
                                            component="span"
                                            variant="body2"
                                            
                                        >
                                            {order.status}
                                        </Typography> */}
                                        <div>
                                            {
                                                getStatusInfo(order.statusId, order.status, order.id)

                                            }


                                        </div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <HistoryItem items={order.products}></HistoryItem>
                                </AccordionDetails>
                            </Accordion>)
                    })

                    : <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }}>
                        <CircularProgress color="secondary" />
                    </div>
            }


        </div>
    );
}